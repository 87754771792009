"use client";

import Link from "next/link";
import Image from "next/image";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/20/solid";
import { getDayAndMonth } from "@utils/getDayAndMonth";
import { useWindowSize } from "@utils/hooks";
import { motion } from "framer-motion";
import { useState } from "react";
import { joinClassNames } from "@utils/helpers";

interface Props {
  event: {
    Name: string;
    Url: string;
    Header: string;
    Region: string | null;
    Date: { Start: string | null; End: string | null; Range: string | null };
    Images: { BannerSmall: string; DefaultSmall: string; Portrait: string };
  };
}

export default function EventLink({ event }: Props) {
  const windowSize = useWindowSize();
  const [hoveringDate, setHoveringDate] = useState(false);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  return (
    <Link
      data-testid={`event-${event.Url}`}
      href={event.Url}
      onMouseEnter={(e) => {
        e.preventDefault();
        setHoveringDate(true);
        setDateRangeVisible(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        setHoveringDate(false);
        setTimeout(() => setDateRangeVisible(false), 200);
      }}
      className="relative flex flex-col gap-1 hover:bg-indigo-50/75 p-2 w-full rounded-2xl transition-colors ease-in-out duration-500"
    >
      <div className="relative">
        <Image
          src={
            windowSize.width && windowSize.width > 640
              ? event.Images.Portrait.trim()
              : event.Images.BannerSmall.trim()
          }
          alt={event.Name}
          width={192}
          height={windowSize.width && windowSize.width > 640 ? 224 : 96}
          className="object-cover w-full aspect-[2/1] sm:aspect-[6/7] rounded-xl"
        />
        {event.Date.Range ? (
          <div
            className={joinClassNames(
              "hidden sm:flex items-center absolute bottom-2 right-2 ml-2 p-1 rounded-lg text-sm bg-indigo-100 text-indigo-800",
              dateRangeVisible ? "gap-1" : ""
            )}
          >
            <CalendarIcon className="h-4 w-4 shrink-0" />
            <motion.span
              initial={{
                width: 0,
                height: "20px",
                opacity: 0,
              }}
              animate={
                hoveringDate
                  ? {
                      width: "auto",
                      height: "auto",
                      opacity: 1,
                      transition: { opacity: { delay: 0.3 } },
                    }
                  : {
                      width: 0,
                      height: "20px",
                      opacity: 0,
                      transition: {
                        width: { delay: 0.2 },
                        height: { delay: 0.2 },
                      },
                    }
              }
              className="pointer-events-none will-change-transform"
            >
              {event.Date.Range}
            </motion.span>
          </div>
        ) : event.Date.Start ? (
          <div
            className={joinClassNames(
              "hidden sm:flex items-center absolute bottom-2 right-2 ml-2 p-1 rounded-lg text-sm bg-indigo-100 text-indigo-800",
              dateRangeVisible ? "gap-1" : ""
            )}
          >
            <CalendarIcon className="h-4 w-4 shrink-0" />
            <motion.span
              initial={{
                width: 0,
                height: "20px",
                opacity: 0,
              }}
              animate={
                hoveringDate
                  ? {
                      width: "auto",
                      height: "auto",
                      opacity: 1,
                      transition: { opacity: { delay: 0.3 } },
                    }
                  : {
                      width: 0,
                      height: "20px",
                      opacity: 0,
                      transition: {
                        width: { delay: 0.2 },
                        height: { delay: 0.2 },
                      },
                    }
              }
              className="pointer-events-none will-change-transform"
            >
              {getDayAndMonth(
                event.Date.Start,
                event.Date.End &&
                  event.Date.Start.split("T")[0] !==
                    event.Date.End.split("T")[0]
                  ? {
                      day: "numeric",
                      month: "short",
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }
                  : {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      timeZone:
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    }
              )}
              {event.Date.End &&
              event.Date.Start.split("T")[0] !==
                event.Date.End.split("T")[0] ? (
                <>
                  {" "}
                  to{" "}
                  {getDayAndMonth(event.Date.End, {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  })}
                </>
              ) : (
                <></>
              )}
            </motion.span>
          </div>
        ) : (
          <></>
        )}
      </div>
      <p className="text-lg font-semibold text-black">{event.Name}</p>
      <p className="text-gray-500">{event.Header}</p>
      {event.Date.Range ? (
        <span className="flex sm:hidden gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-indigo-100 text-indigo-800 max-w-full">
          <CalendarIcon className="h-4 w-4 shrink-0" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {event.Date.Range}
          </span>
        </span>
      ) : event.Date.Start ? (
        <span className="flex sm:hidden gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-indigo-100 text-indigo-800 max-w-full">
          <CalendarIcon className="h-4 w-4 shrink-0" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {getDayAndMonth(
              event.Date.Start,
              event.Date.End &&
                event.Date.Start.split("T")[0] !== event.Date.End.split("T")[0]
                ? {
                    day: "numeric",
                    month: "short",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }
                : {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  }
            )}
            {event.Date.End &&
            event.Date.Start.split("T")[0] !== event.Date.End.split("T")[0] ? (
              <>
                {" "}
                to{" "}
                {getDayAndMonth(event.Date.End, {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                })}
              </>
            ) : (
              <></>
            )}
          </span>
        </span>
      ) : (
        <></>
      )}
      {event.Region ? (
        <span className="flex gap-0.5 items-center p-1 rounded-lg w-fit text-sm bg-gray-100 text-gray-800 max-w-full">
          <MapPinIcon className="h-4 w-4" />
          <span className="max-w-[calc(100%-18px)] truncate">
            {event.Region}
          </span>
        </span>
      ) : (
        <></>
      )}
    </Link>
  );
}
