import { AllocatedArea, GaArea } from "@types";
import { COUNTRY_CODE } from "./constants";

export function joinClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const currencyLookup: Record<
  string,
  { currency: string; locale: string }
> = {
  nz: { currency: "NZD", locale: "en-NZ" },
  us: { currency: "USD", locale: "en-US" },
  au: { currency: "AUD", locale: "en-AU" },
};

export const getCurrencyFormat = (num: number | string) => {
  const currencyInfo = currencyLookup[COUNTRY_CODE ?? "nz"];
  if (!currencyInfo) {
    throw new Error("Unsupported country code");
  }

  return Number(num) >= -1 && (num || num === 0)
    ? Intl.NumberFormat(currencyInfo.locale, {
        style: "currency",
        currency: currencyInfo.currency,
      }).format(Number(num))
    : null;
};

export function range(start: number, stop: number, step = 1) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
}

export const toCapital = (string: string) => {
  if (!string?.trim()) return "";
  const words = string.split(" ");
  const upperWords = words.map(
    (word) => `${word[0].toUpperCase()}${word.slice(1).toLowerCase()}`
  );
  return upperWords.join(" ");
};

export function getCookieValue(key: string) {
  if (typeof window === "undefined" || !document.cookie) {
    return "";
  }

  const cookiePairs = document.cookie.split(";");

  const cookies: Record<string, string> = cookiePairs.reduce(
    (prevObj, currPair) => ({
      ...prevObj,
      [currPair.split("=")[0].trim()]: currPair.split("=")[1].trim(),
    }),
    {}
  );

  return cookies[key];
}

export function handlePricesectionNameEqual(areas: {
  allocated: AllocatedArea[];
  ga: GaArea[];
  gaHasExtras: boolean;
}): boolean {
  const uniquePricesectionNames = new Set<string>();

  areas.allocated.forEach((allocatedArea) => {
    uniquePricesectionNames.add(allocatedArea.pricesection.name);
  });

  areas.ga.forEach((gaArea) => {
    uniquePricesectionNames.add(gaArea.pricesection.name);
  });

  const pricesectionNamesEqual = uniquePricesectionNames.size === 1;

  if (!pricesectionNamesEqual) {
    return false;
  }

  return true;
}
