import type { Session } from "@supabase/supabase-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { getCookieValue } from "./helpers";
import { createBrowserClient } from "@supabase/ssr";
import { SUPABASE_ANON_KEY, SUPABASE_URL } from "./constants";

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  return windowSize;
}

export function useIsInViewport<T extends HTMLElement>() {
  const targetRef = useRef<T | null>(null);
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const observerRefValue: HTMLElement | null = targetRef.current;

    if (!observerRefValue) return;

    const observer = new IntersectionObserver(
      (entries) => {
        setIsInViewport(entries[0].isIntersecting);
      },
      { threshold: 0 }
    );

    observer.observe(observerRefValue);

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, []);

  const scrollToTarget = useCallback(
    (block?: "nearest" | "start" | "end" | "center") => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({
          behavior: "smooth",
          block: block || "nearest",
        });
      }
    },
    [targetRef]
  );

  return { targetRef, scrollToTarget, isInViewport };
}

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useLatestCookieValue(cookieName: string) {
  const [latestCookie, setLatestCookie] = useState<string | undefined>(
    getCookieValue(cookieName)
  );

  useEffect(() => {
    const checkCookie = () => {
      const cookieValue = getCookieValue(cookieName);

      if (cookieValue !== latestCookie) {
        setLatestCookie(cookieValue);
      }
    };

    if (!latestCookie) {
      checkCookie();
    }

    const interval = setInterval(checkCookie, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [cookieName, latestCookie]);

  return latestCookie;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useSupabaseSession() {
  const supabase = createBrowserClient(SUPABASE_URL!, SUPABASE_ANON_KEY!);
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_, session) => {
      setSession(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase]);

  return session;
}

export function useHydrated() {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  return hydrated;
}
